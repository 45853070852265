<template>
  <v-btn
    class="float-end"
    outlined
    small
    color="primary"
    :to="{ path: pathName }"
  >
    <v-icon left> mdi-plus </v-icon>
    {{ name }}
  </v-btn>
</template>

<script>
export default {
  name: "CommonAdd",
  props: ["pathName", "name"],
};
</script>
